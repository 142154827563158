import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../ALL Css/ALL Dashboard CSS/AdminHome.css';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AdminHome = ({ children }) => {
  const [menuData, setMenuData] = useState([]);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
  const [LoadedComponent, setLoadedComponent] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const profilePopupRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [profileData, setProfileData] = useState({});
  const [photo, setPhoto] = useState(null);



  useEffect(() => {
    const storedBreadcrumb = sessionStorage.getItem('breadcrumb');
    if (storedBreadcrumb) {
      setBreadcrumb(storedBreadcrumb);
    } else {
      setBreadcrumb('');
    }
  }, [location.pathname]);


  const iconMapping = {
    "Dashboard": "bi bi-house",
    "User Management": "bi bi-person",
    "Student Enrollment": "bi bi-file-earmark-person",
    "Batch Management": "bi bi-calendar",
    "Teacher": "bi bi-person-check",
    "LMS`": "bi bi-book",
    "Exam Management Portal": "bi bi-file-earmark-text",
    "Inventory Management": "bi bi-box",
    "Demo class management system": "bi bi-play-circle",
    "Enquiry": "bi bi-info-circle",
    "Doubt solving classes credit system": "bi bi-question-circle",
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
        const response = await fetch(`${API_BASE_URL}/menus?SUCode=${storedUserDetails.SUCode}&UserType=${storedUserDetails.UserType}&EMailID=${storedUserDetails.EmailID}`, {
          method: 'POST',
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        setError('Error fetching menu: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchMenuData();
  }, []);

  const toggleSubmenu = (menu) => {
    if (!isSidebarVisible) {
      setIsSidebarVisible(true);
    }
  
    const submenus = menuData.filter((item) => item.ParentCode === menu.MenuCode);
  
    if (submenus.length === 0) {
      // No submenus, directly load the menu content
      const fullBreadcrumb = menu.MenuName;
      setBreadcrumb(fullBreadcrumb);
      sessionStorage.setItem('breadcrumb', fullBreadcrumb);
  
      // Dynamically load the component for the menu
      const loadComponent = async () => {
        try {
          const Component = await import(`../../Components/AdminPart/AdminDetails/${menu.FrmName}.js`);
          setLoadedComponent(() => lazy(() => Promise.resolve({ default: Component.default })));
        } catch (error) {
          console.error(`Component not found for FrmName: ${menu.FrmName}`, error);
          setLoadedComponent(() => NotFound); // Set the 404 component as fallback
        }
      };
  
      loadComponent();
    } else {
      // Submenus exist, toggle submenu visibility
      setExpandedMenu((prev) => (prev === menu.MenuCode ? null : menu.MenuCode));
    }
  
    setActiveMenu(menu.MenuCode);
  };

  const NotFound = () => <div>404 Page Not Found</div>; 

  const handleSubmenuClick = (submenu, parentMenuName) => {
    const fullBreadcrumb = `${parentMenuName} > ${submenu.MenuName}`;
    setBreadcrumb(fullBreadcrumb);
    sessionStorage.setItem('breadcrumb', fullBreadcrumb);
  
    // Dynamically load the component for the submenu
    const loadComponent = async () => {
      try {
        const Component = await import(`../../Components/AdminPart/AdminDetails/${submenu.FrmName}.js`);
        setLoadedComponent(() => lazy(() => Promise.resolve({ default: Component.default })));
      } catch (error) {
        console.error(`Component not found for FrmName: ${submenu.FrmName}`, error);
        setLoadedComponent(() => NotFound); // Set the 404 component as fallback
      }
    };
  
    loadComponent();
  };

  // Clear breadcrumb when navigating back from submenu to menu
  useEffect(() => {
    if (location.pathname === '/adminhome') {
      sessionStorage.removeItem('breadcrumb');
      setBreadcrumb('');
    }
  }, [location.pathname]);

  const renderMenuItems = () => {
    const level1Menus = menuData.filter((item) => item.MenuLevel === 'Level1');
    return level1Menus.map((menu) => (
      <div key={menu.MenuCode}>
        <div
          className="Menu-Item"
          onClick={() => toggleSubmenu(menu)}
          style={{
            color: '#2c384af2',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            transition: 'background-color 0.3s, color 0.3s',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#36f';
            e.currentTarget.style.borderRadius = '5px';
            e.currentTarget.style.color = 'white';
            e.currentTarget.style.padding = '5px';
            const icon = e.currentTarget.querySelector('i');
            if (icon) {
              icon.style.color = 'white';
            }
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = '';
            e.currentTarget.style.color = '#2c384af2';
            const icon = e.currentTarget.querySelector('i');
            if (icon) {
              icon.style.color = '';
            }
          }}
        >
          {isSidebarVisible && (
            <>
              <i
                className={iconMapping[menu.MenuName]}
                style={{
                  marginRight: '15px',
                  fontWeight: 'bold',
                  fontSize: isSidebarVisible ? '20px' : '30px',
                  transition: 'font-size 0.3s ease',
                  color: activeMenu === menu.MenuCode ? '#4361EE' : 'inherit',
                }}
              ></i>
              {menu.MenuName}
            </>
          )}
          <span
            style={{
              marginLeft: 'auto',
              transition: 'transform 0.3s',
              transform: expandedMenu === menu.MenuCode ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <i className="bi bi-chevron-down"></i>
          </span>
        </div>
        {expandedMenu === menu.MenuCode && renderSubmenuItems(menu.MenuCode, menu.MenuName)}
      </div>
    ));
  };



  const renderSubmenuItems = (parentMenuCode, parentMenuName) => {
    const level2Submenus = menuData.filter((submenu) => submenu.ParentCode === parentMenuCode && submenu.MenuLevel === 'Level2');
    if (level2Submenus.length === 0) return null;

    return (
      <div className="submenu">
        {level2Submenus.map((submenu) => (
          <div key={submenu.MenuCode} className="submenu-item">
            <div
              onClick={() => handleSubmenuClick(submenu, parentMenuName)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              {submenu.MenuName}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const togglePopup = () => {
    setIsProfilePopupVisible((prev) => !prev);
    if (!isProfilePopupVisible) {
      const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
      if (storedUserDetails) {
        setUserDetails(storedUserDetails);
      }
    }
  };

  useEffect(() => {
    const UserDetails = JSON.parse(sessionStorage.getItem("user"));
    if (UserDetails?.EmailID) {
      axios
        .post(`${API_BASE_URL}/api/profile`, { EmailID: UserDetails.EmailID })
        .then((response) => {
          const { UserName, EmailID, Mobile, WhatsApp, Active, UserImage } = response.data;
          setProfileData({
            name: UserName,
            email: EmailID,
            mobile: Mobile,
            whatsapp: WhatsApp,
            active: Active,
          });
          if (UserImage) {
            setPhoto(`data:image/jpeg;base64,${UserImage}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data", error);
        });
    } else {
      console.error("No email ID found in session storage");
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    setUserDetails(null);
    navigate('/loginselect');
  };

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profilePopupRef.current && !profilePopupRef.current.contains(event.target)) {
        setIsProfilePopupVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;







  return (
    <div className="Container">
      <nav className="Navbar">
        <div className="Adjust-Sidebar" onClick={toggleSidebar}>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-6flbmm"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="MenuIcon"
          >
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
          </svg>
        </div>

        <div className="Funscholar-Logo" style={{ cursor: 'pointer' }} onClick={handleLogoClick}>
          <img src="https://funscholar.infolabsinsight.com/fabicon.ico" alt="Funscholar Logo" />
        </div>

        <div className="Navbar-Heading">
          {breadcrumb}
        </div>

        <div className="profile-container" onClick={togglePopup}>
          <div className="profile-icon">
            {photo ? (
              <img
                src={photo}
                alt="Profile"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
            ) : (
              <i
                className="bi bi-person-circle"
                style={{ fontSize: "30px", marginRight: "50px" }}
              ></i>
            )}
          </div>
        </div>


        {isProfilePopupVisible && userDetails && (
          <div className="profile-popup" ref={profilePopupRef}>
            <p> <div className="profile-icon">
              {photo ? (
                <img
                  src={photo}
                  alt="Profile"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <i
                  className="bi bi-person-circle"
                ></i>
              )}
            </div></p>
            <p> {userDetails.UserName}</p>
            <p><strong>Edit:</strong> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon" role="img" aria-hidden="true" onClick={() => navigate('/profile')}
              style={{ cursor: 'pointer' }}><path fill="var(--ci-primary-color, currentColor)" d="M29.663,482.25l.087.087a24.847,24.847,0,0,0,17.612,7.342,25.178,25.178,0,0,0,8.1-1.345l142.006-48.172,272.5-272.5A88.832,88.832,0,0,0,344.334,42.039l-272.5,272.5L23.666,456.541A24.844,24.844,0,0,0,29.663,482.25Zm337.3-417.584a56.832,56.832,0,0,1,80.371,80.373L411.5,180.873,331.127,100.5ZM99.744,331.884,308.5,123.127,388.873,203.5,180.116,412.256,58.482,453.518Z" class="ci-primary"></path></svg></p>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}

      </nav>

      <div className="container-fluid">
        <div className="sidebar-container">
          {isSidebarVisible ? (
            <div className="sidebar">{renderMenuItems()}</div>
          ) : (
            <div className="sidebar-collapsed">
              {menuData.map((menu) => (
                <div key={menu.MenuCode} className="collapsed-menu" onClick={() => toggleSubmenu(menu)}>
                  <i className={iconMapping[menu.MenuName]}
                    style={{
                      color: activeMenu === menu.MenuCode ? 'blue' : 'inherit',
                    }}
                  ></i>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="Content">
          {children || (LoadedComponent && <Suspense fallback={<div>Loading...</div>}><LoadedComponent /></Suspense>)}
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
