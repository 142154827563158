import React from 'react';
import BoardDetails from '../../AdminPart/AdminDetails/BoardDetails';

const StudyMaterials = () => {

  

  return (
    <div>
       <BoardDetails />
    </div>
  );
};

export default StudyMaterials;
